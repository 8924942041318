import { select } from 'redux-saga/effects';
import { equalIgnoreCase } from '@lms/react-common-helpers/strings';
import { QuoteType } from '../data/quote-type';
import { jurisdictions } from "../components/shared/data";

export const getPostcodeArray = (postcode) => {
  if (!postcode) return [];
  const postcodeArr = postcode.split(" ");
  const regex = /^(([A-Z][A-HJ-Y]?\d[A-Z\d]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?(\d[A-Z]{2}|BFPO ?\d{1,4}|(KY\d|MSR|VG|AI)[ -]?\d{4}|[A-Z]{2} ?\d{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1))$/gim;
  const subst = `$2 $3`;
  return postcodeArr.length > 1
    ? postcodeArr
    : postcode.replace(regex, subst).split(" ");
};

export const parseBigInt = (input) => {
  if (!input) return null;

  return parseFloat(input.replace(',', ''));
}

const tenureMap = {
  0: 'Leasehold',
  1: 'Freehold'
}

const bridgingFinanceRepresentationMap = {
  1: 'Dual',
  2: 'Separate'
}

const parseTenure = (tenure) => tenureMap[tenure];
const parseBridgingFinanceRepresentation = (value) => bridgingFinanceRepresentationMap[value];
const fromProp = ({ value }, fn) => fn && value ? fn(value) : value;
const fromYesNo = (prop) => fromProp(prop, (res) => res === "1") ?? false;
const fromTrait = (prop) => fromProp(prop, (res) => res ? res[0].checked : false) ?? false;

const getCommonProps = ({ jurisdiction, propertyAddress: { value }, tenure, bespokeReferralFee, propertyType, customerLocation, panel }) => ({
    jurisdiction: fromProp(jurisdiction),
    propertyAddress: mapAddress(value),
    tenure: jurisdiction.value === jurisdictions.SCOTLAND.value ? null : fromProp(tenure, parseTenure),
    referralFee: fromProp(bespokeReferralFee),
    propertyType: fromProp(propertyType),
    customerLocation: fromProp(customerLocation),
    panelId: panel ? fromProp(panel) : null,
  });

export const mapAddress = ({ houseName, houseNumber, streetName, districtName, postTown, county, postCode, country }) => {
  const postcodeArray = getPostcodeArray(postCode);

  return {
    houseName,
    houseNumber,
    streetName,
    districtName,
    postTown,
    county,
    postcode1: postcodeArray[0],
    postcode2: postcodeArray[1],
    country: country
  }
}

const getSaleRequest = (quote) => ({
  ...getCommonProps(quote),
  ...getSaleProps(quote),
  ...getSaleOptions(quote),
  ...getSaleTraits(quote)
});

const getSaleProps = ({ transactionAmount, newLender, owners, numberofexpats }) => (
  {
    salePrice: fromProp(transactionAmount, parseBigInt),
    existingLenderId: fromProp(newLender, parseInt),
    numberOfCurrentOwners: fromProp(owners, parseInt),
    numberOfExpats: fromProp(numberofexpats, parseInt),
  });

  const getSaleTraits = ({
    jurisdiction,
    sharedownershiptrait,
    highriseflattrait,
    hmotrait,
    helptobuyloantrait,
    giftedequitytrait,
    auctiontrait,
    firstregistrationtrait,
  }) => ({
    sharedOwnership: fromTrait(sharedownershiptrait),
    highRiseFlat: fromTrait(highriseflattrait),
    multipleOccupancy: jurisdiction.value === jurisdictions.SCOTLAND.value ? null : fromTrait(hmotrait),
    helpToBuyEquityLoan: fromTrait(helptobuyloantrait),
    saleAtUndervalue: jurisdiction.value === jurisdictions.SCOTLAND.value ? null : fromTrait(giftedequitytrait),
    auction: fromTrait(auctiontrait),
    firstRegistration: fromTrait(firstregistrationtrait),
  });

  const getSaleOptions = ({ jurisdiction, studentproperty, ltdcompany, mortgage }) => ({
    studentProperty: jurisdiction.value === jurisdictions.SCOTLAND.value ? null : fromYesNo(studentproperty),
    ltdCompanySale: fromYesNo(ltdcompany),
    outstandingMortgage: jurisdiction.value === jurisdictions.SCOTLAND.value ? null : fromYesNo(mortgage),
  });

const getPurchaseRequest = (quote) => (
  {
    ...getCommonProps(quote),
    ...getPurchaseProps(quote),
    ...getPurchaseOptions(quote),
    ...getPurchaseTraits(quote)
  });

  const getPurchaseOptions = ({ jurisdiction, studentproperty, mainresidence, firsttimebuyer, ltdcompany, obtainmortgage }) => ({
    studentProperty: jurisdiction.value === jurisdictions.SCOTLAND.value ? null : fromYesNo(studentproperty),
    mainResidence: fromYesNo(mainresidence),
    firstTimeBuyers: fromYesNo(firsttimebuyer),
    ltdCompanyPurchase: fromYesNo(ltdcompany),
    customerObtainingMortgage: jurisdiction.value === jurisdictions.SCOTLAND.value ? null : fromYesNo(obtainmortgage),
  });

  const getPurchaseProps = ({ numberofhelptobuy, numberOfBedrooms, numberofgifteddeposits, numberofexpats, otherresidence, owners, newLender, transactionAmount, yearBuilt, bridgingfinancerepresentation }) => ({
    numberOfHelpToBuyIsas: fromProp(numberofhelptobuy),
    numberOfBedrooms: fromProp(numberOfBedrooms, parseInt),
    numberOfGiftedDeposits: fromProp(numberofgifteddeposits, parseInt),
    numberOfExpats: fromProp(numberofexpats, parseInt),
    secondaryUsage: fromProp(otherresidence),
    numberOfOwners: fromProp(owners, parseInt),
    newLenderId: fromProp(newLender, parseInt),
    purchasePrice: fromProp(transactionAmount, parseBigInt),
    yearBuilt: fromProp(yearBuilt),
    bridgingFinanceRepresentation: fromProp(bridgingfinancerepresentation, parseBridgingFinanceRepresentation),
  });

  const getPurchaseTraits = ({
    jurisdiction,
    helptobuyloantrait,
    hmotrait,
    sharedownershiptrait,
    highriseflattrait,
    righttobuytrait,
    newbuildtrait,
    giftedequitytrait,
    jointproprietortrait,
    soleproprietortrait,
    deedofpostponementtrait,
    auctiontrait,
    armedforcestrait,
    firstregistrationtrait,
  }) => ({
    helpToBuyEquityLoan: fromTrait(helptobuyloantrait),
    multipleOccupancy: fromTrait(hmotrait),
    sharedOwnership: fromTrait(sharedownershiptrait),
    highRiseFlat: fromTrait(highriseflattrait),
    rightToBuy: jurisdiction.value === jurisdictions.SCOTLAND.value ? null : fromTrait(righttobuytrait),
    newBuild: fromTrait(newbuildtrait),
    giftedEquity: jurisdiction.value === jurisdictions.SCOTLAND.value ? null : fromTrait(giftedequitytrait),
    jointProprietorSoleMortgagor: jurisdiction.value === jurisdictions.SCOTLAND.value ? null :  fromTrait(jointproprietortrait),
    soleProprietorJointMortgagor: jurisdiction.value === jurisdictions.SCOTLAND.value ? null : fromTrait(soleproprietortrait),
    deedOfPostponement: jurisdiction.value === jurisdictions.SCOTLAND.value ? null : fromTrait(deedofpostponementtrait),
    auction: fromTrait(auctiontrait),
    armedForcesHelpToBuy: fromTrait(armedforcestrait),
    firstRegistration: fromTrait(firstregistrationtrait),
  })

const getTransferOfEquityRequest = (quote) => ({
  ...getCommonProps(quote),
  ...getTransferOfEquityProps(quote)
})

const getTransferOfEquityProps = ({ transactionAmount, owners, ownersaftertransfer, numberOfBedrooms, yearBuilt }) => ({
  considerationAmount: fromProp(transactionAmount, parseBigInt),
  preTransferNumberOfOwners: fromProp(owners, parseInt),
  postTransferNumberOfOwners: fromProp(ownersaftertransfer, parseInt),
  numberOfBedrooms: fromProp(numberOfBedrooms, parseInt),
  yearBuilt: fromProp(yearBuilt)
});

const getRemortgageRequest = (quote) => ({
  ...getCommonProps(quote),
  ...getRemortgageProps(quote), 
  ...getRemortgageOptions(quote),
  ...getRemortgageTraits(quote)
})

const getRemortgageProps = ({ transactionAmount, newLender, owners, otherresidence, ownersaftertransfer, numberofexpats, numberOfBedrooms, yearBuilt, numberofgifteddeposits}) => ({
  mortgageValue: fromProp(transactionAmount, parseBigInt),
  newLenderId: fromProp(newLender, parseInt),
  numberOfOwners: fromProp(owners),
  secondaryUsage: fromProp(otherresidence),
  postTransactionOwners: fromProp(ownersaftertransfer, parseInt),
  numberOfExpats: fromProp(numberofexpats, parseInt),
  numberOfBedrooms: fromProp(numberOfBedrooms, parseInt),
  yearBuilt: fromProp(yearBuilt),
  numberOfGiftedDeposits: fromProp(numberofgifteddeposits, parseInt),
})

const getRemortgageOptions = ({ mortgage, ltdcompany, mainresidence, transferrequired, studentproperty }) => ({
  currentMortgage: fromYesNo(mortgage),
  ltdCompanyRemortgage: fromYesNo(ltdcompany),
  mainResidence: fromYesNo(mainresidence),
  transferOfEquityRequired: fromYesNo(transferrequired),
  studentProperty: fromYesNo(studentproperty),
})

const getRemortgageTraits = ({
  deedofpostponementtrait,
  helptobuyloantrait,
  hmotrait,
  jointproprietortrait,
  soleproprietortrait,
  sharedownershiptrait,
  highriseflattrait,
}) => ({
  deedOfPostponement: fromTrait(deedofpostponementtrait),
  helpToBuyEquityLoan: fromTrait(helptobuyloantrait),
  multipleOccupancy: fromTrait(hmotrait),
  jointProprietorSoleMortgagor: fromTrait(jointproprietortrait),
  soleProprietorJointMortgagor: fromTrait(soleproprietortrait),
  sharedOwnership: fromTrait(sharedownershiptrait),
  highRiseFlat: fromTrait(highriseflattrait),
})

export function* getQuoteRequest() {
  const caseType = yield select((s) => s.quote.type.value)
  const quote = yield select((s) => s.quote[caseType]);
  switch (caseType) {
    case QuoteType.SALE:
      return getSaleRequest(quote);
    case QuoteType.PURCHASE:
      return getPurchaseRequest(quote);
    case QuoteType.TRANSFER_OF_EQUITY:
      return getTransferOfEquityRequest(quote);
    case QuoteType.REMORTGAGE:
      return getRemortgageRequest(quote);
    case QuoteType.SALE_AND_PURCHASE:
      const { bespokeReferralFee, jurisdiction, updateDefaultReferralFee, panel } = quote;
      return [getSaleRequest({ bespokeReferralFee, jurisdiction, updateDefaultReferralFee, panel, ...quote.sale }), getPurchaseRequest({ bespokeReferralFee, jurisdiction, updateDefaultReferralFee, panel, ...quote.purchase })]
    default:
      return null;
  }
}
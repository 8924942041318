import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import { getCheckedState, getJurisdiction, getQuote } from '../../../utils/jsutils';
import css from '../quote.module.css';
import Address from '../../../components/address';
import {
  storeQuoteTenure,
  storeQuoteMortgage,
  storeQuoteOwners,
  storeQuoteExpatsTrait,
  storeQuoteNewLender,
  storeQuoteNumberOfExpats,
  storeQuoteGiftedEquityTrait,
  storeQuoteHMOTrait,
  storeQuoteStudentProperty,
  storeQuoteSharedOwnershipTrait,
  storeQuoteHighRiseFlatTrait,
  storeQuotePropertyType,
  storeQuoteCustomerLocation,
  storeQuotePropertyAddress,
  storeQuoteTransactionAmount,
  storeQuoteHelpToBuyLoanTrait,
  storeQuoteLtdCompany,
  storeQuoteAuctionTrait,
  storeQuoteFirstRegistrationTrait,
} from '../../../redux/actions/actions';
import {
  yesno,
  propertyOwners,
  expatsTrait,
  additionalTraitValues,
  giftedEquitySaleTrait,
  hmoTrait,
  sharedOwnershipTrait,
  highRiseFlatTrait,
  propertyType,
  helpToBuyLoanRedemptionTrait,
  auctionTrait,
  firstRegistrationTrait,
} from '../../../components/shared/data';
import Price from '../components/price';
import DropdownSelectorLayout from '../components/dropdown-selector-layout';
import DropdownSelectorSubLayout from '../components/dropdown-selector-sub-layout';
import ButtonSelectorLayout from '../components/button-selector-layout';
import ButtonSelectorSubLayout from '../components/button-selector-sub-layout';
import CheckboxApply from '../components/checkbox-apply';
import CheckboxSelector from '../../../components/selectors/checkbox/checkboxselector';
import SearchableDropdown from '../../instructQuote/searchable-dropdown';
import ValidationGroup from '../../../components/validation-group';
import { QuoteType } from '../../../data/quote-type';
import Tenure from "../components/tenure";
import { jurisdictions } from '../../../components/shared/data';
import CustomerLocation from '../../../components/customerLocation';

const Sale = ({
  caseType = QuoteType.SALE,
  storePath = null,
  submitted,
  validationHandler,
}) => {
  const dispatch = useDispatch();
  const region = useSelector((store) => getJurisdiction(store, caseType, storePath));
  const getCurrentQuote = (store) => getQuote(store, caseType, storePath);
  const getCurrentCheckedState = (prop) => getCheckedState(storePath ?? caseType, prop);
  const propertyTypeState = () => useSelector((store) => getCurrentQuote(store).propertyType.value);
  const customerLocationState = () => useSelector((store) => getCurrentQuote(store).customerLocation.value);
  const propertyAddress = useSelector((store) => getCurrentQuote(store).propertyAddress?.value);

  const isExpatsChecked = getCurrentCheckedState('expatstrait');
  const isHelpToBuyLoanChecked = getCurrentCheckedState('helptobuyloantrait');
  const isHMOChecked = getCurrentCheckedState('hmotrait');
  const isGiftedEquityChecked = getCurrentCheckedState('giftedequitytrait');
  const isSharedOwnershipChecked = getCurrentCheckedState('sharedownershiptrait');
  const isHighRiseFlatChecked = getCurrentCheckedState('highriseflattrait');
  const isAuctionChecked = getCurrentCheckedState('auctiontrait');
  const isFirstRegistrationChecked = getCurrentCheckedState("firstregistrationtrait");

  const newLenderError = useSelector(
    (store) => getCurrentQuote(store).newLender?.validation.message ?? null,
  );

  const isMortgageToPay = useSelector((store) => getCurrentQuote(store).mortgage.value);
  const isLimitedCompany = useSelector((store) => getCurrentQuote(store).ltdcompany?.value);

  const lenderVal = useSelector((store) => getCurrentQuote(store).newLender?.value);
  const lenders = useSelector((store) => store.lenders.data);
  const lenderState = lenders.find((l) => l.value === lenderVal)?.value;

  const handleAddressChange = (e) => dispatch(storeQuotePropertyAddress(e, storePath));

  useEffect(() => {
    // reset redux store
    if (!isExpatsChecked) {
      dispatch(storeQuoteNumberOfExpats(null, storePath));
    }
  }, [isExpatsChecked]);

  useEffect(() => {
    // reset redux store
    if (isMortgageToPay === '2') {
      dispatch(storeQuoteNewLender(null, storePath));
    }
  }, [isMortgageToPay]);

  useEffect(() => {
    // reset redux store
    if (isLimitedCompany === '1') {
      dispatch(storeQuoteOwners(null, storePath));
    }
  }, [isLimitedCompany]);

  return (
    <>
      <div className={css.toppad2x} />
      <ValidationGroup submitted={submitted} validationHandler={validationHandler}>
        <Address
          id="addr-property-address-sale"
          className={css.addressContainer}
          onChange={handleAddressChange}
          initialState={propertyAddress}
        />
      </ValidationGroup>
      <Price
        title="Sale Price"
        id="currency-input-sale"
        datatestid="currency-input-sale"
        placeholder="Amount"
        persistaction={storeQuoteTransactionAmount}
        storePath={storePath}
        value={useSelector((store) => getCurrentQuote(store).transactionAmount.value)}
        errorMessage={(store) => getCurrentQuote(store).transactionAmount.validation.message}
        errorclass={css.error}
      />
      
      <Tenure 
        region={region}
        persistAction={storeQuoteTenure}
        storePath={storePath}
        selector={(store) => getCurrentQuote(store).tenure.value}
        errorMessage={(store) =>
          getCurrentQuote(store).tenure.validation.message
        }  
      />

      <ButtonSelectorLayout
        title="Is there a mortgage to pay off?"
        id="mortgage-sale"
        datatestid="mortgage-sale"
        buttons={yesno}
        groupname="mortgage"
        persistAction={storeQuoteMortgage}
        storePath={storePath}
        selector={(store) => getCurrentQuote(store).mortgage.value}
        errorMessage={(store) => getCurrentQuote(store).mortgage.validation.message}
      />

      <ButtonSelectorLayout
        title="Is the property being sold in a limited company name?"
        id="limitedcompany-sale"
        datatestid="ltdcompany-sale"
        buttons={yesno}
        groupname="ltdcompany"
        persistAction={storeQuoteLtdCompany}
        storePath={storePath}
        selector={(store) => getCurrentQuote(store).ltdcompany.value}
        errorMessage={(store) => getCurrentQuote(store).ltdcompany.validation.message}
      />

      {region === jurisdictions.SCOTLAND.value && (
        <DropdownSelectorLayout
          title="Type of Property"
          id="property-type-sale"
          datatestid="property-type-sale"
          options={propertyType}
          groupname="propertytype"
          persistAction={storeQuotePropertyType}
          storePath={storePath}
          selector={(store) => getCurrentQuote(store).propertyType.value}
          errorMessage={(store) => getCurrentQuote(store).propertyType.validation.message}
          selectedState={propertyTypeState}
        />
      )}

      <ButtonSelectorLayout
        title="How many people own the property?"
        id="owners-sale"
        datatestid="owners-sale"
        buttons={propertyOwners}
        groupname="owners"
        persistAction={(a, b) => storeQuoteOwners(a, b, caseType)}
        storePath={storePath}
        selector={(store) => getCurrentQuote(store).owners.value}
        errorMessage={(store) => getCurrentQuote(store).owners.validation.message}
        disabled={isLimitedCompany === '1'}
      />

      {isMortgageToPay === '1' && (
        <Row>
          <Col
            md={12}
            xs={12}
            className={classnames(
              css.leftalign,
              css.leftpad2x,
              css.tempSpacing,
              css.label,
            )}
          >
            <SearchableDropdown
              id="existingLenderDropdown-sale"
              label="Name of new lender"
              placeholder="Please select new lender..."
              value={lenderState}
              onChange={(newExistingLender) => {
                const lender = lenders.find((l) => l.value === newExistingLender)?.value;
                dispatch(storeQuoteNewLender(lender, storePath));
              }}
              list={lenders}
              menuClassName={css.lenderDropDown}
              errorMessages={[newLenderError]}
            />
          </Col>
        </Row>
      )}

      <CustomerLocation 
        quoteType="sale"
        persistAction={storeQuoteCustomerLocation}
        storePath={storePath}
        selector={(store) => getCurrentQuote(store).customerLocation.value}
        errorMessage={(store) => getCurrentQuote(store).customerLocation.validation.message}
        selectedState={customerLocationState}
      />

      <CheckboxApply>
        <CheckboxSelector
          id="auctiontrait-sale"
          datatestid="auctiontrait-sale"
          checkboxes={auctionTrait}
          persistaction={storeQuoteAuctionTrait}
          storePath={storePath}
          checkedState={isAuctionChecked}
        />

        <CheckboxSelector
          id="expatstrait-sale"
          datatestid="expatstrait-sale"
          checkboxes={expatsTrait}
          persistaction={storeQuoteExpatsTrait}
          storePath={storePath}
          checkedState={isExpatsChecked}
          selector={(store) => getCurrentQuote(store).expatstrait}
        />

        {isExpatsChecked && (
          <DropdownSelectorSubLayout
            title="How many (max 4)?"
            id="numberofexpats-sale"
            datatestid="numberofexpats-sale"
            options={additionalTraitValues}
            groupname="numberofexpats"
            persistAction={storeQuoteNumberOfExpats}
            storePath={storePath}
            selector={(store) => getCurrentQuote(store).numberofexpats.value}
            errorMessage={(store) => getCurrentQuote(store).numberofexpats.validation.message}
          />
        )}

        {(region === jurisdictions.SCOTLAND.value) && (
          <CheckboxSelector
            id="firstregistrationtrait-sale"
            datatestid="firstregistrationtrait-sale"
            checkboxes={firstRegistrationTrait}
            persistaction={storeQuoteFirstRegistrationTrait}
            storePath={storePath}
            checkedState={isFirstRegistrationChecked}
          />
        )}

        {region !== jurisdictions.SCOTLAND.value && (
          <CheckboxSelector
            id="giftedequitytrait-sale"
            datatestid="giftedequitytrait-sale"
            checkboxes={giftedEquitySaleTrait}
            persistaction={storeQuoteGiftedEquityTrait}
            storePath={storePath}
            checkedState={isGiftedEquityChecked}
          />
        )}

        <CheckboxSelector
          id="helptobuytrait-sale"
          datatestid="helptobuytrait-sale"
          checkboxes={helpToBuyLoanRedemptionTrait}
          persistaction={storeQuoteHelpToBuyLoanTrait}
          storePath={storePath}
          checkedState={isHelpToBuyLoanChecked}
        />

        {region !== jurisdictions.SCOTLAND.value && (
          <>
            <CheckboxSelector
              id="hmotrait-sale"
              datatestid="hmotrait-sale"
              checkboxes={hmoTrait}
              persistaction={storeQuoteHMOTrait}
              storePath={storePath}
              checkedState={isHMOChecked}
            />
            {isHMOChecked && (
              <ButtonSelectorSubLayout
                title="Is this a student property?"
                id="studentproperty-sale"
                datatestid="studentproperty-sale"
                buttons={yesno}
                groupname="studentproperty"
                persistAction={storeQuoteStudentProperty}
                storePath={storePath}
                selector={(store) => getCurrentQuote(store).studentproperty.value}
                errorMessage={(store) => getCurrentQuote(store).studentproperty.validation.message}
              />
            )}
          </>
        )}

        <CheckboxSelector
          id="sharedownershiptrait-sale"
          datatestid="sharedownershiptrait-sale"
          checkboxes={sharedOwnershipTrait}
          persistaction={storeQuoteSharedOwnershipTrait}
          storePath={storePath}
          checkedState={isSharedOwnershipChecked}
        />

        <CheckboxSelector
          id="highriseflattrait-sale"
          datatestid="highriseflattrait-sale"
          checkboxes={highRiseFlatTrait}
          persistaction={storeQuoteHighRiseFlatTrait}
          storePath={storePath}
          checkedState={isHighRiseFlatChecked}
        />

      </CheckboxApply>
    </>
  );
};

Sale.propTypes = {
  caseType: PropTypes.string,
  storePath: PropTypes.string,
  submitted: PropTypes.bool,
  validationHandler: PropTypes.func.isRequired,
};

Sale.defaultProps = {
  caseType: QuoteType.SALE,
  storePath: null,
  submitted: false,
};

export default Sale;

import React from "react";
import css from "./index.module.scss";
import { ReactComponent as Banner } from "../../../components/shared/imgs/banner-graphic.svg";
import User from "../../../components/user/user";

const Header = () => {
  return (
    <div className={css.container}>
      <div className={css.textContainer}>
        <div className={css.userText}>WELCOME <User id="user" datatestid="user" userClass={css.user} /></div>
        <div className={css.text}>What would you like to do today?</div>
      </div>
      <div className={css.image}>
        <Banner />
      </div>
    </div>
  );
};

export default Header;

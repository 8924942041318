import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AuthCheck from "../../components/authcheck/authCheck";
import authState from "../../components/authcheck/authState";
import User from "../../components/user/user";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import css from "./instructConfirmation.module.css";
import Card from "react-bootstrap/Card";
import Spinner from "../../components/spinner"
import { Completed } from "../../components/shared/imgs";
import classnames from "classnames";
import * as logos from "../../components/shared/imgs/lawfirm-logos/index"
import Logo from "../../components/logo/logo";
import InsuranceConfirmation from "../../components/insuranceConfirmation";
import { toQuoteTypeDisplay } from "../../utils/jsutils";
import Layout from '../../layout';

const InstructConfirmation = () => {

  const [isLoading, setIsloading] = useState(true);
  const { forenames } = useSelector((store) => store.preferences);
  const insuranceQuotingPreference = useSelector((store) => store.preferences?.uinsure?.quotingType?.value)
  const showInsurance = insuranceQuotingPreference === "AlwaysIncludeGeneralInsuranceQuote" || insuranceQuotingPreference === "PromptForGeneralInsuranceQuote";
  const instructData = useSelector(store => store.instruct);
  const customer = instructData.customers.quote[0];
  const data = instructData.instructProcessState?.instruction;
  const quoteType = data.length > 1 ? 'Sale and Purchase' : toQuoteTypeDisplay(instructData.instructProcessState?.savedQuote?.data?.quoteType) || "";
  const lawFirm = data[0].lawFirm;
  const caseReferences = data.map((item) => item.caseId);
  const clientName = `${customer.firstName} ${customer.surname}`;
  const giQuoteReference = useSelector(store => store.uinsure?.selectedQuote);
  let confirmationString = 'YOUR INSTRUCTION IS CONFIRMED!'

  if (forenames) {
    confirmationString = `, ${confirmationString}`;
  }

  useEffect(() => {
    if (instructData) setIsloading(false)
  }, [instructData])

  if (isLoading) {
    return (
      <Layout>
        <AuthCheck requiredAuthState={authState.AUTHENTICATED} />
        <Row>
          <Col xs={{ div: 12, offset: 0 }} sm={{ span: 10, offset: 1 }}>
            <Card.Header className={css.headingContainer}>
              <User id="user" datatestid="user" userClass={css.user} />
              <span>{confirmationString}</span>
            </Card.Header>
            <Row>
              <div className={css.loading}>
                <Spinner variant={css.spinner} /> Loading...
              </div>
            </Row>
          </Col>
        </Row>
      </Layout>
    )
  }

  return (
    <Layout>
      <AuthCheck requiredAuthState={authState.AUTHENTICATED} />
      <Row>
        <Col xs={{ div: 12, offset: 0 }} sm={{ span: 10, offset: 1 }}>
          <Card.Header className={css.headingContainer}>
            <User id="user" datatestid="user" userClass={css.user} />
            <span>{confirmationString}</span>
          </Card.Header>
          <Card.Body>
            <Row className={css.box}>
              <Col xs={2} sm={2} xl={1}>
                <span className={css.completedIcon}>
                  <Completed
                    className={css.caseDetailsCompleted}
                  />
                </span>
              </Col>
              {caseReferences.length == 1 &&
                <Col data-testid="case-ref" xs={10} className={css.leftalign}>
                  <p className={classnames(css.flex, css.center, css.contentText, css.banner)}>{quoteType} for {clientName} has been sent to {lawFirm?.name ? lawFirm.name : "the law firm"}.
                    <br />Your case reference is {caseReferences.join(', ')}.</p>
                </Col>
              }
              {caseReferences.length > 1 &&
                <Col data-testid="case-ref" xs={10} className={css.leftalign}>
                  <p className={classnames(css.flex, css.center, css.contentText, css.banner)}>{quoteType} for {clientName} has been sent to {lawFirm?.name ? lawFirm.name : "the law firm"}.
                    <br />Your case references are {caseReferences.join(', ')}.</p>
                </Col>
              }
            </Row>
          </Card.Body>
        </Col>
      </Row>

        <Row>
          <Col xs={{ span: 10, offset: 1 }}>
            <Card.Header className={css.headingContainer}>
              <Logo logos={logos} name={lawFirm?.name ?? ''} logotype="lawfirm" className={classnames(css.lawfirmLogo, css.leftalign)} />
            </Card.Header>
            <Card.Body>
              <div className={classnames(css.bottompad2x, css.toppad2x)}>
                {lawFirm?.name &&
                  <div className={classnames(css.lawFirmDetails, css.contentText)}>{lawFirm.name}</div>
                }
                {lawFirm?.officeAddress?.streetName &&
                  <div className={classnames(css.lawFirmDetails, css.contentText)}>{lawFirm.officeAddress.streetName}</div>
                }
                {lawFirm?.phone &&
                  <div className={classnames(css.lawFirmDetails, css.contentText)}>{lawFirm.phone}</div>
                }
                {lawFirm?.email &&
                  <div className={classnames(css.lawFirmDetails, css.contentText)}>{lawFirm.email}</div>
                }
                <div className={classnames(css.lawFirmDetails, css.contentText)}>The Law Firm will now accept the case and contact the customer directly. LMS will also contact the customer and your Relationship Manager is here to help with any queries.</div>
              </div>
            </Card.Body>
          </Col>
        </Row>

      {giQuoteReference && showInsurance &&
        <div data-testid='instruct-gi'>
          <InsuranceConfirmation
            customerName={clientName}
          />
        </div>
      }
    </Layout>
  );
};

export default InstructConfirmation;

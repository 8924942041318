import { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import css from "./index.module.scss";
import AuthCheck from "../../../components/authcheck/authCheck";
import Layout from "../../../layout";
import useSelectApi from "../../../hooks/useSelectApi";
import { IOfficeAttributeCategory } from "../../../types/select-api/ResponseTypes";
import Loading from "../../../components/loading";
import Button from "../../../components/button/button";
import useRenderKey from "@lms/react-common-components/useRenderKey";
import * as notifications from "../../../data/notifications";
import { useNotifications } from "../../../hooks/notifications";
import Breadcrumb from "../components/breadcrumbs";

interface IOfficeAttributesProps {
  officeId: string;
}

const OfficeAttributes = ({ officeId }: IOfficeAttributesProps) => {
  const { showSuccess, showError } = useNotifications();
  const [isLoading, setIsLoading] = useState(true);
  const [isDirty, setIsDirty] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const { renderKey, updateRenderKey } = useRenderKey();
  const [officeAttributes, setOfficeAttributes] = useState<
    Array<IOfficeAttributeCategory>
  >([]);
  const [selectedAttributes, setSelectedAttributes] = useState<Array<string>>(
    []
  );

  const breadcrumbs = [
    { name: 'Home', path: '/' },
    { name: 'Offices', path: '/manage-offices' },
    { name: 'Attributes', isActive: true },
  ];

  const { getOfficeAttributes, saveOfficeAttributes } = useSelectApi({
    suppressErrorRedirect: true,
    suppressErrorMessages: false,
  });

  useEffect(() => {
    const loadOfficeAttributes = async () => {
      setIsLoading(true);
      const result = await getOfficeAttributes(officeId);
      if (result.success) {
        setOfficeAttributes(result.data!);
        const initialSelectedAttributes = new Array<string>();
        result.data!.forEach((category) => {
          category.attributes.forEach((attribute) => {
            if (attribute.selected) {
              initialSelectedAttributes.push(attribute.id);
            }
          });
        });
        setSelectedAttributes(initialSelectedAttributes);
      } else {
        setOfficeAttributes([]);
      }

      setIsLoading(false);
    };

    loadOfficeAttributes();
  }, [officeId, renderKey]);

  useEffect(() => {
    const selectedAttributeIds = officeAttributes
      .flatMap((category) => category.attributes)
      .filter((attribute) => attribute.selected)
      .map((attribute) => attribute.id);

    const isUnchanged =
      selectedAttributeIds.length === selectedAttributes.length &&
      selectedAttributeIds.every((id) => selectedAttributes.includes(id));

    setIsDirty(!isUnchanged);
  }, [officeAttributes, selectedAttributes]);

  const handleSelectedChange = (attributeId: string, isChecked: boolean) => {
    setSelectedAttributes((prevState) => {
      let newSelectedAttributes = [...prevState];

      if (isChecked && !newSelectedAttributes.includes(attributeId)) {
        newSelectedAttributes.push(attributeId);
      } else if (!isChecked && newSelectedAttributes.includes(attributeId)) {
        newSelectedAttributes = newSelectedAttributes.filter(
          (id) => id !== attributeId
        );
      }

      return newSelectedAttributes;
    });
  };

  const handleSave = () => {
    const saveAttributes = async () => {
      setIsSaving(true);
      const result = await saveOfficeAttributes(officeId, {
        attributes: selectedAttributes,
      });
      if (result.success) {
        showSuccess(notifications.saveOfficeAttributes.positive);
        updateRenderKey();
      } else {
        showError(notifications.saveOfficeAttributes.negative);
      }
      setIsSaving(false);
    };

    saveAttributes();
  };

  return (
    <Layout>
      <AuthCheck requiredAuthState="AUTHENTICATED" />
      {isLoading && <Loading />}
      {!isLoading && officeAttributes.length > 0 && (
        <Row className={css.bottompad2x}>
          <Col md={1} xs={1}></Col>
          <Col md={10} xs={10}>
            <Row>

              <Breadcrumb items={breadcrumbs} />

              <Col md={12} xs={12}>
                <div className={css.header}>OFFICE ATTRIBUTES</div>
              </Col>
            </Row>
            {isDirty && (
              <div className="alert alert-warning" role="alert">
                You have unsaved changes.
              </div>
            )}
            <Row className="bottompad-2x">
              {officeAttributes.map((category, categoryIndex) => (
                <Col key={categoryIndex} md={4} xs={12} className="mb-4">
                  <div className={css.categoryContainer}>
                    <div className={css.categoryHeader}>
                      {category.categoryName}
                    </div>
                    <hr className={css.separator} />
                    {category.attributes.map((attribute) => (
                      <div
                        className="d-flex align-items-center mb-2"
                        key={attribute.id}
                      >
                        <label className={css.checkboxContainer}>
                          <span className={css.label}>{attribute.name}</span>
                          <input
                            type="checkbox"
                            disabled={isSaving}
                            checked={selectedAttributes.includes(attribute.id)}
                            onChange={(e) =>
                              handleSelectedChange(
                                attribute.id,
                                e.target.checked
                              )
                            }
                          />
                          <span className={css.customCheck}></span>
                        </label>
                      </div>
                    ))}
                  </div>
                </Col>
              ))}
            </Row>
            <Row className="bottompad-2x">
              <div className={css.buttonContainer}>
                <Button
                  id=""
                  datatestid=""
                  disabled={isSaving}
                  btnClass={css.saveButton}
                  onClick={() => handleSave()}
                  type="button"
                  text="SAVE ATTRIBUTES"
                />
              </div>
            </Row>
          </Col>
          <Col md={1} xs={1}></Col>
        </Row>
      )}
    </Layout>
  );
};

export default OfficeAttributes;

import React, { useLayoutEffect, useEffect, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import css from "./dashboard.module.css";
import { AuthContext } from "../../utils/authProvider";
import AuthCheck from "../../components/authcheck/authCheck";
import authState from "../../components/authcheck/authState";
import ControlPanel from "./control-panel/control-panel";
import NewUserPreferenceMessage from "../../components/newuserpreferencemessage";
import EmulatePanel from "./emulate/EmulatePanel";
import Layout from "../../layout";
import LawFirmDashboard from "./law-firm-dashboard";
import Header from "./header";
import BrokerDashboard from "./broker-dashboard";

const DashBoard = () => {
  const { getUser, getAccessToken } = useContext(AuthContext);

  const [isBroker, setisBroker] = useState(false);
  const [isLawfirm, setisLawfirm] = useState(false);
  const [isNewUser, setisNewUser] = useState(false);
  const [canEmulateUser, setCanEmulateUser] = useState(false);

  const newUserCheck = useSelector(
    (store) => store.preferences.lastUpdated.value
  );

  const preferencesReceived = useSelector(
    (store) => store.preferences.loaded.value
  );

  const handleClose = () => {
    window.sessionStorage.setItem("preferenceSettingDeferred", "true");
    setisNewUser(false);
  };

  const preferencesDeferred = window.sessionStorage.getItem(
    "preferenceSettingDeferred"
  );

  useEffect(() => {
    if (preferencesDeferred) {
      setisNewUser(false);
    } else if (newUserCheck === null && preferencesReceived) {
      setisNewUser(true);
    } else {
      setisNewUser(false);
    }
  }, [newUserCheck, preferencesReceived]);

  useLayoutEffect(() => {
    async function init() {
      await getAccessToken();
      let user = await getUser();
      if (user.profile.roles.includes("BROKER_USER.PAGE")) setisBroker(true);

      if (user.profile.roles.includes("LAW_FIRM_USER.PAGE")) setisLawfirm(true);

      if (user.profile.roles.includes("EMULATE_USER.PAGE")) {
        setCanEmulateUser(true);
      }
    }
    init();
  }, []);

  return (
    <Layout>
      <AuthCheck requiredAuthState={authState.AUTHENTICATED} />
      <Row>
        <Col md={12} xs={12}>
          <NewUserPreferenceMessage
            id="preferencemessagemodal"
            datatestid="preferencemessagemodal"
            show={isNewUser}
            onHide={handleClose}
            getAccessToken={getAccessToken}
          />
        </Col>
      </Row>

      <Row className={css.bottompad2x}>
        <Col md={1} xs={1}></Col>
        <Col md={10} xs={10}>
          <Row>
            <Header />
          </Row>

          {canEmulateUser && (
            <Row className={css.bottompad2x}>
              <Col md={12} xs={12}>
                <div className={css.emulateSection}>
                  <EmulatePanel />
                </div>
              </Col>
            </Row>
          )}

          {isLawfirm && <LawFirmDashboard />}

          {isBroker && <BrokerDashboard />}

          {!isLawfirm && (
            <>
              <Row className={css.bottompad2x}>
                <Col md={12} xs={12}>
                  <div className={css.welcomeText}>YOUR PREFERENCES</div>
                </Col>
              </Row>

              <Row className={css.bottompad2x}>
                <Col md={12} xs={12}>
                  <div className={css.prefsSection}>
                    <ControlPanel />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Col>
        <Col md={1} xs={1}></Col>
      </Row>
    </Layout>
  );
};

export default DashBoard;

import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import css from "./index.module.scss";
import classnames from "classnames";

import AuthCheck from "../../../components/authcheck/authCheck";
import authState from "../../../components/authcheck/authState";
import Layout from "../../../layout";
import Table from "../components/action-table";
import useSelectApi from "../../../hooks/useSelectApi";
import { IMemberPanel } from "../../../types/select-api/ResponseTypes";
import Loading from "../../../components/loading";
import Button from "../../../components/button/button";

function ManagePanelMemberships() {
  const [isLoading, setIsLoading] = useState(true);
  const [panels, setPanels] = useState<Array<IMemberPanel>>([]);
  const { getPanels } = useSelectApi({
    suppressErrorRedirect: true,
    suppressErrorMessages: false,
  });

  const renderCell = (row: IMemberPanel, header: string) => {
    switch (header) {
      case "PANEL NAME":
        return row.name;
      case "PANEL OWNER":
        return row.ownerName;
      case "PARTICIPANT":
        return row.participant ?? "No Participant";
      default:
        return "";
    }
  };

  const renderActions = (row: IMemberPanel) => (
    <div>
      <Button
        id=""
        datatestid=""
        btnClass={classnames(css.actionButton)}
        onClick={() => console.log(`Editing pricing for panel ${row.id}`)}
        type="button"
        text="EDIT PRICING"
      />
    </div>
  );

  useEffect(() => {
    const loadPanels = async () => {
      setIsLoading(true);

      let hasMorePages = true;
      let currentPage = 0;
      const result: IMemberPanel[] = [];

      while (hasMorePages) {
        currentPage += 1;
        const apiPage = await getPanels({
          pageNumber: currentPage,
          pageSize: 100,
        });

        if (!apiPage.success) {
          return [];
        } else {
          result.push(...(apiPage.data?.items ?? []));
          hasMorePages = apiPage.data?.hasNextPage ?? false;
        }
      }
      setPanels(result);
      setIsLoading(false);
    };

    loadPanels();
  }, []);

  return (
    <Layout>
      <AuthCheck requiredAuthState={authState.AUTHENTICATED} />
      {isLoading && <Loading />}
      {!isLoading && (
        <Row className={css.bottompad2x}>
          <Col md={1} xs={1}></Col>
          <Col md={10} xs={10}>
            <Row>
              <Col md={12} xs={12}>
                <div className={css.header}>YOUR PANELS</div>
              </Col>
            </Row>

            <Row className={classnames(css.panelInstruction)}>
              <div className={classnames(css.panelInstructionText)}>
                PANEL DETAILS
              </div>
            </Row>

            <Row>
              <div className={classnames(css.tableDescription)}>
                Here are the panels your firm or offices are currently a member
                of
              </div>
            </Row>
            <Row className={css.tableContainer}>
              <Table
                data={panels}
                noData="Your firm and its offices aren't currently a member of any panels"
                headers={["PANEL NAME", "PANEL OWNER", "PARTICIPANT"]}
                renderCell={renderCell}
                renderActions={renderActions}
              />
            </Row>
          </Col>
          <Col md={1} xs={1}></Col>
        </Row>
      )}
    </Layout>
  );
}

export default ManagePanelMemberships;

export const jurisdictions = {
  ENGLAND: { displayName: "England", value: "England" },
  WALES: { displayName: "Wales", value: "Wales" },
  SCOTLAND: { displayName: "Scotland", value: "Scotland" },
};

export const jurisdictionsWithNone = {
  ...jurisdictions,
  NONE: { displayName: "None\n(set per quote)", value: null },
};

export const setAsDefaultReferralFee = [
  {
    name: "Set this as my default for new quotes",
    value: 1,
    checked: false,
    overlay: false,
  },
];
export const tenures = [
  { name: "Freehold", value: "1" },
  { name: "Leasehold", value: "0" },
];
export const yesno = [
  { name: "Yes", value: "1" },
  { name: "No", value: "2" },
];
export const otherresidences = [
  { name: "Buy To Let", value: "BuyToLet" },
  { name: "Holiday Let", value: "HolidayLet" },
  { name: "Second Home", value: "SecondHome" },
];
export const otherresidencesftb = [
  { name: "Buy To Let", value: "BuyToLet" },
  { name: "Holiday Let", value: "HolidayLet" },
];
export const propertyType = [
  { name: "Please select", value: "" },
  { name: "Detached house", value: "DetachedHouse" },
  { name: "Semi detached house", value: "SemiDetachedHouse" },
  { name: "Terraced house", value: "TerracedHouse" },
  { name: "Detached bungalow", value: "DetachedBungalow" },
  { name: "Semi detached bungalow", value: "SemiDetachedBungalow" },
  { name: "Purpose built flat", value: "PurposeBuiltFlat" },
  { name: "Terraced bungalow", value: "TerracedBungalow" },
  { name: "Coach house", value: "CoachHouse" },
];
export const propertyOwners = [
  { name: "1", value: "1" },
  { name: "2", value: "2" },
  { name: "3", value: "3" },
  { name: "4", value: "4" },
];
export const armedForcesTrait = [
  {
    name: "Armed Forces Help To Buy",
    value: 1,
    checked: false,
    overlay: false,
  },
];
export const auctionTrait = [
  { name: "Auction", value: 1, checked: false, overlay: false },
];
export const deedOfPostponementTrait = [
  { name: "Deed of Postponement", value: 1, checked: false, overlay: false },
];
export const expatsTrait = [
  { name: "Expats", value: 1, checked: false, overlay: false },
];
export const additionalTraitValues = [
  { name: "Please select", value: "" },
  { name: "1", value: "1" },
  { name: "2", value: "2" },
  { name: "3", value: "3" },
  { name: "4", value: "4" },
];
export const giftedDepositTrait = [
  { name: "Gifted Deposit", value: 1, checked: false, overlay: false },
];
export const giftedEquityTrait = [
  {
    name: "Gifted Equity",
    value: 1,
    checked: false,
    // overlay text temporarily removed until content is provided.
    // overlay: true,
    // overlaytext: "TBC",
  },
];

export const giftedEquitySaleTrait = [
  {
    name: "Sale at undervalue",
    value: 1,
    checked: false,
    // overlay: true,
    // overlaytext: "TBC",
  },
];

export const helpToBuyTrait = [
  {
    name: "Help to Buy/Lifetime ISA",
    value: 1,
    checked: false,
    // overlay: true,
    // overlaytext: "TBC",
  },
];
export const helpToBuyLoanTrait = [
  { name: "Help to Buy Equity Loan", value: 1, checked: true, overlay: false },
];
export const helpToBuyLoanRedemptionTrait = [
  { name: "Redemption of Help to Buy Equity Loan", value: 1, checked: true, overlay: false },
]
export const hmoTrait = [
  {
    name: "House of Multiple Occupancy",
    value: 1,
    checked: false,
  },
];
export const jointProprietorTrait = [
  {
    name: "Joint Proprietor Sole Mortgagor",
    value: 1,
    checked: false,
    overlay: false,
  },
];
export const soleProprietorTrait = [
  {
    name: "Sole Proprietor Joint Mortgagor",
    value: 1,
    checked: false,
    overlay: false,
  },
];
export const newBuildTrait = [
  { name: "New Build", value: 1, checked: false, overlay: false },
];
export const firstRegistrationTrait = [
  { name: "First Registration", value: 1, checked: false, overlay: false },
];
export const rightToBuyTrait = [
  { name: "Right to Buy", value: 1, checked: false, overlay: false },
];
export const sharedOwnershipTrait = [
  { name: "Shared Ownership", value: 1, checked: false, overlay: false },
];
export const highRiseFlatTrait = [
  { name: "High Rise Flat", value: 1, checked: false, overlay: false },
];
export const notifications = [
  {
    name: "Email me with every update",
    value: "Email",
    checked: false,
    overlay: false,
  },
  {
    name: "Text me with each update",
    value: "Text",
    checked: false,
    overlay: false,
  },
  {
    name: "Show me updates on Select",
    value: "WhenCheckingWebsite",
    checked: false,
    overlay: false,
  },
];

export const uinsureoptions = [
  {
    name: "Do not include an insurance quote - switch off the Uinsure integration",
    value: "DoNotIncludeGeneralInsuranceQuote",
    checked: false,
    overlay: false,
  },
  {
    name: "Ask me each time whether I want a Uinsure quote",
    value: "PromptForGeneralInsuranceQuote",
    checked: false,
    overlay: false,
  },
  {
    name: "Always include a Uinsure quote with each conveyancing quote",
    value: "AlwaysIncludeGeneralInsuranceQuote",
    checked: false,
    overlay: false,
  },
  {
    name: `Pass customer to Uinsure - if a Uinsure sale is successful you will still earn referral commission`,
    value: "PassOnLeadWithoutQuote",
    checked: false,
    overlay: false,
  },
];

export const insuranceChoice = [
  {
    name: "I will complete the Home Insurance myself",
    value: "UserComplete",
    checked: false,
    overlay: false,
  },
  {
    name: "Ask Uinsure to assist my customer to complete their Home Insurance",
    value: "SendLead",
    checked: false,
    overlay: false,
  }
];

export const preferredPricingModel = [
  { name: "Select", value: "FixedPrice" },
  { name: "Marketplace", value: "MarketPlace" },
];

export const preferredSortOrder = [
  { name: "Lowest Price", value: "Lowest", disabled: false },
  { name: "Favourites", value: "Favourites", disabled: true },
  { name: "Highest Rating", value: "HighestRanking", disabled: true },
];

export const toeReferralFee = 50;

export const numberOfBedrooms = [
  { name: "Please select", value: "" },
  { name: "1", value: "1" },
  { name: "2", value: "2" },
  { name: "3", value: "3" },
  { name: "4", value: "4" },
  { name: "5", value: "5" },
  { name: "6", value: "6" },
];

export const giYearBuilt = [
  { name: "Please select", value: "" },
  { name: "Pre 1750", value: "1750" },
  { name: "1750 - 1869", value: "1869" },
  { name: "1870 - 1899", value: "1899" },
  { name: "1900 - 1919", value: "1919" },
  { name: "1920 - 1945", value: "1945" },
  { name: "1946 - 1959", value: "1959" },
  { name: "1960 - 1979", value: "1979" },
  { name: "1980 - 1989", value: "1989" },
  { name: "1990 - 1999", value: "1999" },
  { name: "2000 - 2009", value: "2009" },
  { name: "2010 - 2019", value: "2019" },
  { name: "2020 onwards", value: new Date().getFullYear() },
];

export const bridgingFinance = [
  { name: "Bridging Finance", value: 1, checked: false, overlay: false },
];

export const bridgingFinanceRepresentationValues = [
  { name: "Please select", value: "" },
  { name: "Dual representation", value: "1" },
  { name: "Separate representation", value: "2" },
];

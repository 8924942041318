import React from "react";
import css from './index.module.scss';
import ReactTable from 'react-bootstrap/Table';

interface IActionTableProps<T> {
  data: T[];
  headers: string[]; 
  noData?: string;
  renderCell: (row: T, header: string) => React.ReactNode; 
  renderActions: (row: T) => React.ReactNode; 
}

const ActionTable = <T,>({ data, headers, noData, renderCell, renderActions }: IActionTableProps<T>) => {
  const TableRows = () => {
    return (
      <>
        {data.map((item, idx) => (
          <tr key={idx}>
            {headers.map((header, headerIdx) => (
              <td key={headerIdx}>{renderCell(item, header)}</td>
            ))}
            <td>{renderActions(item)}</td> {}
          </tr>
        ))}
      </>
    );
  };

  const TableBody = () => (
    <tbody className={css.tableBody}>
      <TableRows />
    </tbody>
  );

  const TableHeader = () => (
    <thead className={css.header}>
      <tr>
        {headers.map((header, idx) => (
          <th key={idx}>{header}</th>
        ))}
        <th></th> {}
      </tr>
    </thead>
  );

  if (!data || data.length < 1) {
    return noData ? <p>{noData}</p> : null;
  }

  return (
    <ReactTable className={css.table}>
        <TableHeader />
        <TableBody />
    </ReactTable>
  );
};

export default ActionTable;

import React from 'react';
import { Breadcrumb as BootstrapBreadcrumb } from 'react-bootstrap';
import css from './index.module.scss'; 
import classNames from 'classnames';

interface BreadcrumbItem {
  name: string;
  path?: string;
  isActive?: boolean;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  return (
    <BootstrapBreadcrumb className={css.breadcrumbContainer}>
      {items.map((item, index) => (
        <BootstrapBreadcrumb.Item
          key={index}
          className={classNames(css.breadcrumbItem, item.isActive ? css.breadcrumbItemActive : '')}
          active={item.isActive}
          onClick={() => item.path && window.location.assign(item.path)}
        >
          {item.name}
        </BootstrapBreadcrumb.Item>
      ))}
    </BootstrapBreadcrumb>
  );
};

export default Breadcrumb;

import React from "react";
import css from "./index.module.scss";
import classNames from "classnames";

interface IActionButtonProps {
  title: string;
  description: string;
  onClick: () => void;
  borderColor: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const ActionButton = ({title, onClick, description, borderColor, icon: Icon}: IActionButtonProps) => {
  return (
    <div className={css.wrapper} onClick={onClick}>
      <div className={css.background} style={{ backgroundColor: borderColor }} />
      <div className={css.container}>
        <div className={css.content}>
          <div className={css.row1}>
            <div className={classNames(css.item, css.item1)}>
              <Icon />
            </div>
            <div className={classNames(css.item, css.item2, css.text)}>
              {title}
            </div>
          </div>
          <div className={classNames(css.item, css.row2, css.text)}>{description}</div>
        </div>
      </div>
    </div>
  );
};

export default ActionButton;

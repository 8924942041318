import React, { createRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCheckedState, getJurisdiction } from "../../../utils/jsutils";
import css from "../quote.module.css";
import classnames from "classnames";
import { Row, Col } from "react-bootstrap";
import Address from "../../../components/address"
import { getQuote } from "../../../utils/jsutils";
import { jurisdictions } from "../../../components/shared/data";

// actions
import {
  storeQuoteTenure,
  storeQuoteLtdCompany,
  storeQuoteObtainMortgage,
  storeQuoteMainResidence,
  storeQuoteFirstTimeBuyer,
  storeQuoteOwners,
  storeQuoteExpatsTrait,
  storeQuoteNumberOfExpats,
  storeQuoteNewLender,
  storeQuoteArmedForcesTrait,
  storeQuoteAuctionTrait,
  storeQuoteDeedOfPostponementTrait,
  storeQuoteGiftedDepositTrait,
  storeQuoteGiftedEquityTrait,
  storeQuoteHelpToBuyTrait,
  storeQuoteHMOTrait,
  storeQuoteNumberOfHelpToBuy,
  storeQuoteStudentProperty,
  storeQuoteHelpToBuyLoanTrait,
  storeQuoteNumberOfGiftedDeposits,
  storeQuoteJointProprietorTrait,
  storeQuoteSoleProprietorTrait,
  storeQuoteNewBuildTrait,
  storeQuoteRightToBuyTrait,
  storeQuoteSharedOwnershipTrait,
  storeQuoteHighRiseFlatTrait,
  storeQuotePropertyType,
  storeQuoteCustomerLocation,
  storeQuoteOtherResidence,
  storeQuotePropertyAddress,
  storeQuoteTransactionAmount,
  storeDateOfBirth,
  storeQuoteNumberOfBedrooms,
  storeQuoteYearBuilt,
  storeQuoteBridgingFinance,
  storeQuoteBridgingFinanceRepresentation,
  storeQuoteFirstRegistrationTrait,
} from "../../../redux/actions/actions";

//data
import {
  yesno,
  propertyOwners,
  expatsTrait,
  additionalTraitValues,
  armedForcesTrait,
  auctionTrait,
  deedOfPostponementTrait,
  giftedDepositTrait,
  giftedEquityTrait,
  helpToBuyTrait,
  hmoTrait,
  helpToBuyLoanTrait,
  jointProprietorTrait,
  soleProprietorTrait,
  newBuildTrait,
  rightToBuyTrait,
  sharedOwnershipTrait,
  highRiseFlatTrait,
  propertyType,
  otherresidences,
  otherresidencesftb,
  giYearBuilt,
  numberOfBedrooms,
  bridgingFinance,
  bridgingFinanceRepresentationValues,
  firstRegistrationTrait,
} from "../../../components/shared/data";

//components
import Price from "../components/price";
import DropdownSelectorLayout from "../components/dropdown-selector-layout";
import DropdownSelectorSubLayout from "../components/dropdown-selector-sub-layout";
import ButtonSelectorLayout from "../components/button-selector-layout";
import ButtonSelectorSubLayout from "../components/button-selector-sub-layout";
import CheckboxSelector from "../../../components/selectors/checkbox/checkboxselector";
import DateInput from "../../../components/dateInput";
import SearchableDropdown from "../../../pages/instructQuote/searchable-dropdown";
import ValidationGroup from "../../../components/validation-group";
import { QuoteType } from "../../../data/quote-type";
import Tenure from "../components/tenure";
import CustomerLocation from '../../../components/customerLocation';

const Purchase = ({ caseType = QuoteType.PURCHASE, storePath = null, submitted, validationHandler }) => {
  const dispatch = useDispatch();
  const region = useSelector((store) => getJurisdiction(store, caseType, storePath));

  const getCurrentQuote = (store) => getQuote(store, caseType, storePath);
  const getCurrentCheckedState = (prop) => getCheckedState(storePath ?? caseType, prop);
  const customerLocationState = () => useSelector((store) => getCurrentQuote(store).customerLocation.value)
  
  const lenders = useSelector((store) => store.lenders)?.data;

  const isMainResidence = useSelector(
    (store) => getCurrentQuote(store).mainresidence.value
  );

  const isFirstTimeBuyer = useSelector(
    (store) => getCurrentQuote(store).firsttimebuyer.value
  );

  const isLimitedCompany = useSelector(
    (store) => getCurrentQuote(store).ltdcompany.value
  );

  const isExpatsChecked = getCurrentCheckedState("expatstrait");
  const isGiftedDepositChecked = getCurrentCheckedState("gifteddeposittrait");
  const isHelpToBuyChecked = getCurrentCheckedState("helptobuytrait");
  const isHMOChecked = getCurrentCheckedState("hmotrait");
  const isArmedForceChecked = getCurrentCheckedState("armedforcestrait");
  const IsAuctionChecked = getCurrentCheckedState("auctiontrait");
  const isBridgingFinanceChecked = getCurrentCheckedState("bridgingfinance");
  const isDeedOfPostponementChecked = getCurrentCheckedState("deedofpostponementtrait");
  const isGiftedEquityChecked = getCurrentCheckedState("giftedequitytrait");
  const isHelpToBuyLoanChecked = getCurrentCheckedState("helptobuyloantrait");
  const isJointProprietorChecked = getCurrentCheckedState("jointproprietortrait");
  const isSoleProprietorChecked = getCurrentCheckedState("soleproprietortrait");
  const isNewBuildChecked = getCurrentCheckedState("newbuildtrait");
  const isRightToBuyChecked = getCurrentCheckedState("righttobuytrait");
  const isSharedOwnershipChecked = getCurrentCheckedState("sharedownershiptrait");
  const isHighRiseFlatChecked = getCurrentCheckedState("highriseflattrait");
  const isFirstRegistrationChecked = getCurrentCheckedState("firstregistrationtrait");

  const homeOwnersCount = useSelector(
    (store) => getCurrentQuote(store).owners.value
  );

  const giPreference = useSelector((store) => store.preferences.uinsure?.quotingType?.value);

  const showGIFields =
    giPreference === "AlwaysIncludeGeneralInsuranceQuote"
      || giPreference === "PassOnLeadWithoutQuote";

  const newBuildCheckboxRef = createRef();
  const helpToBuyCheckboxRef = createRef();

  const helpToBuyLoanClick = (checkedState) => {
    if (newBuildCheckboxRef?.current)
      if (checkedState !== newBuildCheckboxRef.current.checked)
        newBuildCheckboxRef.current.click();
  };

  const newBuildClick = (checkedState) => {
    if (helpToBuyCheckboxRef?.current)
      if (!checkedState && helpToBuyCheckboxRef.current.checked)
        helpToBuyCheckboxRef.current.click();
  }

  const lenderVal = useSelector(
    (store) => getCurrentQuote(store).newLender.value
  );
  const lenderState = lenders.find((l) => l.value === lenderVal)?.value;

  const propertyAddress = useSelector((store) => getCurrentQuote(store).propertyAddress?.value);

  useEffect(() => {
    // reset redux store
    if (!isExpatsChecked) {
      dispatch(storeQuoteNumberOfExpats(null, storePath));
    }
    if (!isGiftedDepositChecked) {
      dispatch(storeQuoteNumberOfGiftedDeposits(null, storePath));
    }
    if (!isHelpToBuyChecked) {
      dispatch(storeQuoteNumberOfHelpToBuy(null, storePath));
    }
    if (isMainResidence == '1') {
      dispatch(storeQuoteOtherResidence(null, storePath))
    }
    if (!isBridgingFinanceChecked) {
      dispatch(storeQuoteBridgingFinanceRepresentation(null, storePath));
    }
  }, [isExpatsChecked, isGiftedDepositChecked, isHelpToBuyChecked, isMainResidence, isBridgingFinanceChecked]);

  useEffect(() => {
    // If First Time Buyer set to 'Yes' then set Limited Company to 'No'
    if (isFirstTimeBuyer === "1") {
      dispatch(storeQuoteLtdCompany("2", storePath));
    }
  }, [isFirstTimeBuyer])

  useEffect(() => {
    // If Joint Proprietor is checked set Sole Proprietor to unchecked
    if (isJointProprietorChecked) {
      dispatch(storeQuoteSoleProprietorTrait(null, storePath));
    }
  }, [isJointProprietorChecked])

  useEffect(() => {
    // If Sole Proprietor is checked set Joint Proprietor to unchecked
    if (isSoleProprietorChecked) {
      dispatch(storeQuoteJointProprietorTrait(null, storePath));
    }
  }, [isSoleProprietorChecked])

  useEffect(() => {
    // If Limited Company set to 'Yes' then set First Time Buyer to 'No'
    // Addionally reset Number of Owners and Expats status
    if (isLimitedCompany === "1") {
      dispatch(storeQuoteFirstTimeBuyer("2", storePath));
      dispatch(storeQuoteOwners(null, storePath));
      dispatch(storeQuoteExpatsTrait(null, storePath));
    }
  }, [isLimitedCompany])

  useEffect(() => {
    // If Number of Owners or Expats selected then set Limited Company to 'No'
    if (homeOwnersCount || isExpatsChecked) {
      dispatch(storeQuoteLtdCompany("2", storePath));
    }
  }, [homeOwnersCount, isExpatsChecked])

  const handleAddressChange = (e) => dispatch(storeQuotePropertyAddress(e, storePath));

  return (
    <>
      <div className={css.toppad2x}></div>
      <ValidationGroup submitted={submitted} validationHandler={validationHandler}>
        <Address
          id="addr-property-address-purchase"
          className={css.addressContainer}
          onChange={handleAddressChange}
          initialState={propertyAddress}
        />
      </ValidationGroup>

      <Price
        title="Purchase Price"
        id="currency-input-purchase"
        datatestid="currency-input-purchase"
        placeholder="Amount"
        persistaction={storeQuoteTransactionAmount}
        storePath={storePath}
        errorMessage={(store) =>
          getCurrentQuote(store).transactionAmount.validation.message
        }
        value={useSelector(
          (store) => getCurrentQuote(store).transactionAmount.value
        )}
      />

      <Tenure 
        region={region}
        persistAction={storeQuoteTenure}
        storePath={storePath}
        selector={(store) => getCurrentQuote(store).tenure.value}
        errorMessage={(store) =>
          getCurrentQuote(store).tenure.validation.message
        }  
      />

      <ButtonSelectorLayout
        title="Is the customer obtaining a mortgage?"
        id="obtainmortgage-purchase"
        datatestid="obtainmortgage-purchase"
        buttons={yesno}
        groupname="obtainmortgage"
        persistAction={storeQuoteObtainMortgage}
        storePath={storePath}
        selector={(store) => getCurrentQuote(store).obtainmortgage.value}
        errorMessage={(store) =>
          getCurrentQuote(store).obtainmortgage.validation.message
        }
      />

      <ButtonSelectorLayout
        title="Is the property being purchased in a limited company name?"
        id="limitedcompany-purchase"
        datatestid="ltdcompany-purchase"
        buttons={yesno}
        groupname="ltdcompany"
        persistAction={storeQuoteLtdCompany}
        storePath={storePath}
        selector={(store) => getCurrentQuote(store).ltdcompany.value}
        errorMessage={(store) =>
          getCurrentQuote(store).ltdcompany.validation.message
        }
        disabled={isFirstTimeBuyer === "1" || isExpatsChecked}
      />

      <ButtonSelectorLayout
        title=" Are all of the customers first time buyers?"
        id="firsttimebuyer-purchase"
        datatestid="firsttimebuyer-purchase"
        buttons={yesno}
        groupname="firsttimebuyer"
        persistAction={storeQuoteFirstTimeBuyer}
        storePath={storePath}
        selector={(store) => getCurrentQuote(store).firsttimebuyer.value}
        errorMessage={(store) =>
          getCurrentQuote(store).firsttimebuyer.validation.message
        }
        disabled={isLimitedCompany === "1"}
      />

      <ButtonSelectorLayout
        title="Will this be the customer's main residence?"
        id="mainresidence-purchase"
        datatestid="mainresidence-purchase"
        buttons={yesno}
        groupname="mainresidence"
        persistAction={storeQuoteMainResidence}
        storePath={storePath}
        selector={(store) => getCurrentQuote(store).mainresidence.value}
        errorMessage={(store) =>
          getCurrentQuote(store).mainresidence.validation.message
        }
      />

      {isMainResidence === "2" && isFirstTimeBuyer === "1" && (
        <ButtonSelectorLayout
          title="If not the main residence, is the property?"
          id="otherresidence-select-ftb-purchase"
          datatestid="otherresidence-select-ftb-purchase"
          buttons={otherresidencesftb}
          groupname="otherresidence"
          persistAction={storeQuoteOtherResidence}
          storePath={storePath}
          selector={(store) => getCurrentQuote(store).otherresidence.value}
          errorMessage={(store) =>
            getCurrentQuote(store).otherresidence.validation.message
          }
        />
      )}

      {isMainResidence === "2" && isFirstTimeBuyer === "2" && (
        <ButtonSelectorLayout
          title="If not the main residence, is the property?"
          id="otherresidence-select-purchase"
          datatestid="otherresidence-select-purchase"
          buttons={otherresidences}
          groupname="otherresidence"
          persistAction={storeQuoteOtherResidence}
          storePath={storePath}
          selector={(store) => getCurrentQuote(store).otherresidence.value}
          errorMessage={(store) =>
            getCurrentQuote(store).otherresidence.validation.message
          }
        />
      )}

      {(region === jurisdictions.SCOTLAND.value || showGIFields) && (
        <DropdownSelectorLayout
          title="Type of Property"
          id="property-type-purchase"
          datatestid="property-type-purchase"
          options={propertyType}
          groupname="propertytype"
          persistAction={storeQuotePropertyType}
          storePath={storePath}
          selector={(store) => getCurrentQuote(store).propertyType.value}
          errorMessage={(store) =>
            getCurrentQuote(store).propertyType.validation.message
          }
        />
      )}

      {showGIFields && (
        <DropdownSelectorLayout
          title="Number of Bedrooms"
          id="number-bedrooms-purchase"
          datatestid="number-bedrooms-purchase"
          options={numberOfBedrooms}
          groupname="NumberBedrooms"
          persistAction={storeQuoteNumberOfBedrooms}
          storePath={storePath}
          selector={(store) => getCurrentQuote(store).numberOfBedrooms.value}
          errorMessage={(store) =>
            getCurrentQuote(store).numberOfBedrooms.validation.message
          }
        />
      )}

      {showGIFields && (
        <DropdownSelectorLayout
          title="Year Built"
          id="year-built-purchase"
          datatestid="year-built-purchase"
          options={giYearBuilt}
          groupname="yearBuilt"
          persistAction={storeQuoteYearBuilt}
          storePath={storePath}
          selector={(store) => getCurrentQuote(store).yearBuilt.value}
          errorMessage={(store) =>
            getCurrentQuote(store).yearBuilt.validation.message
          }
        />
      )}

      <ButtonSelectorLayout
        title="How many people will own the property?"
        id="owners-purchase"
        datatestid="owners-purchase"
        buttons={propertyOwners}
        groupname="owners"
        persistAction={storeQuoteOwners}
        storePath={storePath}
        selector={(store) => getCurrentQuote(store).owners.value}
        errorMessage={(store) =>
          getCurrentQuote(store).owners.validation.message
        }
        disabled={isLimitedCompany === "1"}
      />

      {showGIFields && (
        <DateInput
          id="dob-purchase"
          datatestid="dob-purchase"
          collectionValues={(store) => getCurrentQuote(store).DateOfBirth.value}
          storeModel={(store) => getCurrentQuote(store)}
          persistAction={storeDateOfBirth}
          storePath={storePath}
          homeOwnersCount={homeOwnersCount}
          errorclass={css.error}
        />
      )}

      <Row>
        <Col
          md={12}
          xs={12}
          className={classnames(
            css.leftalign,
            css.leftpad2x,
            css.tempSpacing,
            css.label
          )}
        >
          <SearchableDropdown
            id="existingLenderDropdown-purchase"
            label="Name of new lender"
            placeholder="Please select new lender..."
            value={lenderState}
            onChange={(newExistingLender) => {
              const lender = lenders.find(
                (l) => l.value === newExistingLender
              )?.value;
              dispatch(storeQuoteNewLender(lender, storePath));
            }}
            list={lenders}
            menuClassName={css.lenderDropDown}
            errorMessages={[
              useSelector(
                (store) => getCurrentQuote(store).newLender.validation.message
              ),
            ]}
          />
        </Col>
      </Row>

      <CustomerLocation 
        quoteType="purchase"
        persistAction={storeQuoteCustomerLocation}
        storePath={storePath}
        selector={(store) => getCurrentQuote(store).customerLocation.value}
        errorMessage={(store) => getCurrentQuote(store).customerLocation.validation.message}
        selectedState={customerLocationState}
      />

      <div className={css.traitContainer}>

        <div className={css.traitItem}>
          <CheckboxSelector
            id="armedforcestrait-purchase"
            datatestid="armedforcestrait-purchase"
            checkboxes={armedForcesTrait}
            persistaction={storeQuoteArmedForcesTrait}
            storePath={storePath}
            checkedState={isArmedForceChecked}
          />
        </div>

        <div className={css.traitItem}>
          <CheckboxSelector
            id="auctiontrait-purchase"
            datatestid="auctiontrait-purchase"
            checkboxes={auctionTrait}
            persistaction={storeQuoteAuctionTrait}
            storePath={storePath}
            checkedState={IsAuctionChecked}
          />
        </div>

        <div className={css.traitItem}>
          <CheckboxSelector
            id="bridgingfinance-purchase"
            datatestid="bridgingfinance-purchase"
            checkboxes={bridgingFinance}
            persistaction={storeQuoteBridgingFinance}
            storePath={storePath}
            checkedState={isBridgingFinanceChecked}
          />
          {isBridgingFinanceChecked && (
            <DropdownSelectorSubLayout
              title="Representation:"
              id="bridgingfinancerepresentation-purchase"
              datatestid="bridgingfinancerepresentation-purchase"
              options={bridgingFinanceRepresentationValues}
              groupname="bridgingfinancerepresentation"
              persistAction={storeQuoteBridgingFinanceRepresentation}
              storePath={storePath}
              selector={(store) => getCurrentQuote(store).bridgingfinancerepresentation.value}
              errorMessage={(store) =>
                getCurrentQuote(store).bridgingfinancerepresentation.validation.message
              }
            />
          )}
        </div>

        <div className={css.traitItem}>
          {(region !== jurisdictions.SCOTLAND.value) && (
            <CheckboxSelector
              id="deedofpostponementtrait-purchase"
              datatestid="deedofpostponementtrait-purchase"
              checkboxes={deedOfPostponementTrait}
              persistaction={storeQuoteDeedOfPostponementTrait}
              storePath={storePath}
              checkedState={isDeedOfPostponementChecked}
            />
          )}
        </div>

        <div className={css.traitItem}>
          <CheckboxSelector
            id="expatstrait-purchase"
            datatestid="expatstrait-purchase"
            checkboxes={expatsTrait}
            persistaction={storeQuoteExpatsTrait}
            storePath={storePath}
            checkedState={isExpatsChecked}
            disabled={isLimitedCompany === "1"}
          />
          {isExpatsChecked && (
            <DropdownSelectorSubLayout
              title="How many (max 4)?"
              id="numberofexpats-purchase"
              datatestid="numberofexpats-purchase"
              options={additionalTraitValues}
              groupname="numberofexpats"
              persistAction={storeQuoteNumberOfExpats}
              storePath={storePath}
              selector={(store) => getCurrentQuote(store).numberofexpats.value}
              errorMessage={(store) =>
                getCurrentQuote(store).numberofexpats.validation.message
              }
            />
          )}          
        </div>

        <div className={css.traitItem}>
          {(region === jurisdictions.SCOTLAND.value) && (
            <CheckboxSelector
              id="firstregistrationtrait-purchase"
              datatestid="firstregistrationtrait-purchase"
              checkboxes={firstRegistrationTrait}
              persistaction={storeQuoteFirstRegistrationTrait}
              storePath={storePath}
              checkedState={isFirstRegistrationChecked}
            />
          )}
        </div>

        <div className={css.traitItem}>
          <CheckboxSelector
            id="gifteddeposittrait-purchase"
            datatestid="gifteddeposittrait-purchase"
            checkboxes={giftedDepositTrait}
            persistaction={storeQuoteGiftedDepositTrait}
            storePath={storePath}
            checkedState={isGiftedDepositChecked}
          />
          {isGiftedDepositChecked && (
            <DropdownSelectorSubLayout
              title="How many (max 4)?"
              id="numberofgifteddeposits-purchase"
              datatestid="numberofgifteddeposits-purchase"
              options={additionalTraitValues}
              groupname="numberofgifteddeposits"
              persistAction={storeQuoteNumberOfGiftedDeposits}
              storePath={storePath}
              selector={(store) =>
                getCurrentQuote(store).numberofgifteddeposits.value
              }
              errorMessage={(store) =>
                getCurrentQuote(store).numberofgifteddeposits.validation.message
              }
            />
          )}
        </div>

        <div className={css.traitItem}>
          {(region !== jurisdictions.SCOTLAND.value) && (
            <CheckboxSelector
              id="giftedequitytrait-purchase"
              datatestid="giftedequitytrait-purchase"
              checkboxes={giftedEquityTrait}
              persistaction={storeQuoteGiftedEquityTrait}
              storePath={storePath}
              checkedState={isGiftedEquityChecked}
            />
          )}
        </div>

        <div className={css.traitItem}>
          {isFirstTimeBuyer === "1" && (
            <CheckboxSelector
              id="helptobuytrait-purchase"
              datatestid="helptobuytrait-purchase"
              checkboxes={helpToBuyTrait}
              persistaction={storeQuoteHelpToBuyTrait}
              storePath={storePath}
              checkedState={isHelpToBuyChecked}
            />
          )}
          {isHelpToBuyChecked && isFirstTimeBuyer === "1" && (
            <DropdownSelectorSubLayout
              title="How many (max 4)?"
              id="numberofhelptobuy-purchase"
              datatestid="numberofhelptobuy-purchase"
              options={additionalTraitValues}
              groupname="numberofhelptobuy"
              persistAction={storeQuoteNumberOfHelpToBuy}
              storePath={storePath}
              selector={(store) => getCurrentQuote(store).numberofhelptobuy.value}
              errorMessage={(store) =>
                getCurrentQuote(store).numberofhelptobuy.validation.message
              }
            />
          )}         
        </div>

        <div className={css.traitItem}>
          {isFirstTimeBuyer === "1" && (
            <CheckboxSelector
              id="helptobuyloantrait-purchase"
              datatestid="helptobuyloantrait-purchase"
              checkboxes={helpToBuyLoanTrait}
              persistaction={storeQuoteHelpToBuyLoanTrait}
              storePath={storePath}
              checkedState={isHelpToBuyLoanChecked}
              checkboxref={helpToBuyCheckboxRef}
              checkboxclickevent={(checked) => helpToBuyLoanClick(checked)}
            />
          )}
        </div>

        <div className={css.traitItem}>
          <CheckboxSelector
            id="hmotrait-purchase"
            datatestid="hmotrait-purchase"
            checkboxes={hmoTrait}
            persistaction={storeQuoteHMOTrait}
            storePath={storePath}
            checkedState={isHMOChecked}
          />
          {isHMOChecked && (
            <>
              <div className={css.infoText}>
                Note: Please check you have selected that this is not the
                customer&apos;s Main Residence and that it is a Buy To Let to get
                an accurate quote.
              </div>
              {!showGIFields && (
                <DropdownSelectorSubLayout
                  title="How many bedrooms?"
                  id="numberofhmo-purchase"
                  datatestid="numberofhmo-purchase"
                  options={numberOfBedrooms}
                  groupname="numberofhmo"
                  persistAction={storeQuoteNumberOfBedrooms}
                  storePath={storePath}
                  selector={(store) => getCurrentQuote(store).numberOfBedrooms.value}
                  errorMessage={(store) =>
                    getCurrentQuote(store).numberOfBedrooms.validation.message
                  }
                />
              )}
              {(region !== jurisdictions.SCOTLAND.value) && (
                <ButtonSelectorSubLayout
                  title="Is this a student property?"
                  id="studentproperty-purchase"
                  datatestid="studentproperty-purchase"
                  buttons={yesno}
                  groupname="studentproperty"
                  persistAction={storeQuoteStudentProperty}
                  storePath={storePath}
                  selector={(store) => getCurrentQuote(store).studentproperty.value}
                  errorMessage={(store) =>
                    getCurrentQuote(store).studentproperty.validation.message
                  }
                />
              )}

            </>
          )}
        </div>

        <div className={css.traitItem}>
          {(region !== jurisdictions.SCOTLAND.value) && (
            <CheckboxSelector
              id="jointproprietortrait-purchase"
              datatestid="jointproprietortrait-purchase"
              checkboxes={jointProprietorTrait}
              persistaction={storeQuoteJointProprietorTrait}
              storePath={storePath}
              checkedState={isJointProprietorChecked}
            />
          )}
        </div>

        <div className={css.traitItem}>
          {(region !== jurisdictions.SCOTLAND.value) && (
            <CheckboxSelector
              id="soleproprietortrait-purchase"
              datatestid="soleproprietortrait-purchase"
              checkboxes={soleProprietorTrait}
              persistaction={storeQuoteSoleProprietorTrait}
              storePath={storePath}
              checkedState={isSoleProprietorChecked}
            />
          )}
        </div>

        <div className={css.traitItem}>
          <CheckboxSelector
            id="newbuildtrait-purchase"
            datatestid="newbuildtrait-purchase"
            checkboxes={newBuildTrait}
            persistaction={storeQuoteNewBuildTrait}
            storePath={storePath}
            checkedState={isNewBuildChecked}
            checkboxref={newBuildCheckboxRef}
            checkboxclickevent={(checked) => newBuildClick(checked)}
          />
        </div>
            
        <div className={css.traitItem}>
          {(region !== jurisdictions.SCOTLAND.value) && (
            <CheckboxSelector
              id="righttobuytrait-purchase"
              datatestid="righttobuytrait-purchase"
              checkboxes={rightToBuyTrait}
              persistaction={storeQuoteRightToBuyTrait}
              storePath={storePath}
              checkedState={isRightToBuyChecked}
            />
          )}
        </div>

        <div className={css.traitItem}>
          <CheckboxSelector
            id="sharedownershiptrait-purchase"
            datatestid="sharedownershiptrait-purchase"
            checkboxes={sharedOwnershipTrait}
            persistaction={storeQuoteSharedOwnershipTrait}
            storePath={storePath}
            checkedState={isSharedOwnershipChecked}
          />
        </div>

        <div className={css.traitItem}>
          <CheckboxSelector
            id="highriseflattrait-purchase"
            datatestid="highriseflattrait-purchase"
            checkboxes={highRiseFlatTrait}
            persistaction={storeQuoteHighRiseFlatTrait}
            storePath={storePath}
            checkedState={isHighRiseFlatChecked}
          />
        </div>

      </div>

    </>
  );
};

export default Purchase;

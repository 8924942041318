import React, { useContext } from "react";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../utils/authProvider";
import { useConfiguration } from "../context/configuration-context";
import { ISelectApiOptions } from "../types/select-api/ISelectApiOptions";
import { useNotifications } from "../hooks/notifications";
import * as ResponseTypes from "../types/select-api/ResponseTypes";
import _ from "lodash";

interface IResult<T> {
  success: boolean;
  data?: T;
  error?: string;
}

interface IHeaders extends Record<string, string> {
  Authorization: string;
}

interface IRequestConfig {
  headers: IHeaders;
}

export interface IPagedRequest {
  pageNumber?: number;
  pageSize?: number;
}

export interface IOfficeAttributesRequest {
  attributes: Array<string>;
}

export default function useSelectApi(
  options: ISelectApiOptions | undefined = undefined
) {
  const { apiBaseAddress } = useConfiguration();
  const history = useHistory();
  const { getAccessToken } = useContext(AuthContext);
  const { showError } = useNotifications();

  if (!apiBaseAddress) {
    console.error("ERROR: Cannot set API base address");
  }

  const authenticate = async (): Promise<IRequestConfig> => {
    const accessToken = await getAccessToken();
    return {
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : "",
      },
    };
  };

  const handleUnauthorised = () => {
    if (!options?.suppressErrorRedirect) {
      history.push("/no-auth");
    }
  };

  interface ISelectApiError {
    title?: string;
    detail?: string;
  }

  const showErrorMessage = (e: AxiosError<ISelectApiError>) => {
    if (!options?.suppressErrorMessages) {
      if (e.response?.data?.detail) {
        showError(e.response.data.detail);
      } else {
        showError("An error occurred");
      }
    }
  };

  const execute = async <T>(
    fn: (cfg: IRequestConfig) => Promise<T>
  ): Promise<IResult<T>> => {
    try {
      const config = await authenticate();
      const data = await fn(config);
      return { success: true, data };
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e?.response?.status === 401) {
          handleUnauthorised();
        } else {
          showErrorMessage(e);
        }
      } else {
        showError("An error occurred");
      }
    }

    return { success: false, error: "Request failed" };
  };

  const searchAddress = async (
    searchTerm: string
  ): Promise<IResult<ResponseTypes.IAddressSearchResultItem[]>> =>
    execute(async (cfg): Promise<ResponseTypes.IAddressSearchResultItem[]> => {
      const url = `${apiBaseAddress}/addresses?searchTerm=${searchTerm}`;
      const { data } = await axios.get<
        ResponseTypes.IAddressSearchResultItem[]
      >(url, cfg);
      return data;
    });

  const drillDownAddress = async (
    containerId: string
  ): Promise<IResult<ResponseTypes.IAddressSearchResultItem[]>> =>
    execute(async (cfg): Promise<ResponseTypes.IAddressSearchResultItem[]> => {
      const url = `${apiBaseAddress}/addresses?containerid=${containerId}`;
      const { data } = await axios.get<
        ResponseTypes.IAddressSearchResultItem[]
      >(url, cfg);
      return data;
    });

  const lookupAddress = async (
    addressId: string
  ): Promise<IResult<ResponseTypes.IAddressGetResult>> =>
    execute(async (cfg): Promise<ResponseTypes.IAddressGetResult> => {
      const url = `${apiBaseAddress}/addresses/${addressId}`;
      const { data } = await axios.get<ResponseTypes.IAddressGetResult>(
        url,
        cfg
      );
      return data;
    });

  const getPanels = async (
    request: IPagedRequest
  ): Promise<
    IResult<ResponseTypes.IPagedResponse<ResponseTypes.IMemberPanel>>
  > =>
    execute(
      async (
        cfg
      ): Promise<ResponseTypes.IPagedResponse<ResponseTypes.IMemberPanel>> => {
        const url = `${apiBaseAddress}/panels?pageNumber=${request.pageNumber}&pageSize=${request.pageSize}`;
        const { data } = await axios.get<
          ResponseTypes.IPagedResponse<ResponseTypes.IMemberPanel>
        >(url, cfg);
        return data;
      }
    );

  const getOffices = async (
    request: IPagedRequest
  ): Promise<IResult<ResponseTypes.IPagedResponse<ResponseTypes.IOffice>>> =>
    execute(
      async (
        cfg
      ): Promise<ResponseTypes.IPagedResponse<ResponseTypes.IOffice>> => {
        const url = `${apiBaseAddress}/offices?pageNumber=${request.pageNumber}&pageSize=${request.pageSize}`;
        const { data } = await axios.get<
          ResponseTypes.IPagedResponse<ResponseTypes.IOffice>
        >(url, cfg);
        return data;
      }
    );

  const getOfficeAttributes = async (
    officeId: string
  ): Promise<IResult<Array<ResponseTypes.IOfficeAttributeCategory>>> =>
    execute(
      async (cfg): Promise<Array<ResponseTypes.IOfficeAttributeCategory>> => {
        const url = `${apiBaseAddress}/offices/${officeId}/attributes`;
        const { data } = await axios.get<
          Array<ResponseTypes.IOfficeAttributeCategory>
        >(url, cfg);
        return data;
      }
    );

  const saveOfficeAttributes = async (
    officeId: string,
    request: IOfficeAttributesRequest
  ): Promise<IResult<void>> =>
    execute(async (cfg: IRequestConfig) => {
      const { data } = await axios.put(
        `${apiBaseAddress}/offices/${officeId}/attributes`,
        request,
        cfg as AxiosRequestConfig
      );
      return data;
    });

  return {
    searchAddress,
    drillDownAddress,
    lookupAddress,
    getPanels,
    getOffices,
    getOfficeAttributes,
    saveOfficeAttributes
  };
}

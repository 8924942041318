import React from "react";
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { resetQuoteData } from "../../../redux/actions/actions" 
import css from "./index.module.scss";
import ActionButton from "../components/action-button";
import { ReactComponent as NewQuoteIcon } from "../../../components/shared/imgs/new-quote-icon.svg"
import { ReactComponent as CaseProgressIcon } from "../../../components/shared/imgs/case-progress-icon.svg";
import { ReactComponent as SavedQuoteIcon } from "../../../components/shared/imgs/saved-quotes-icon.svg";

const BrokerDashboard = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  
  const handleClick = (location: string) => {
    history.push(location)
  }

  const handleNewQuoteClick = (location: string) => {
    dispatch(resetQuoteData())
    history.push(location)
  }

  return (
    <div className={css.container}>
      <div className={css.item}>
        <ActionButton
          title="START A NEW QUOTE"
          onClick={() => handleNewQuoteClick("/quote")}
          description="Got a new client who needs conveyancing?"
          borderColor="var(--purple)"
          icon={NewQuoteIcon}
        />
      </div>
      <div className={css.item}>
        <ActionButton
          title="INSTRUCT A SAVED QUOTE"
          onClick={() => handleClick("/view-quotes")}
          description="Want to proceed with a case?"
          borderColor="var(--light-blue)"
          icon={SavedQuoteIcon}
        />
      </div>
      <div className={css.item}>
        <ActionButton
          title="VIEW YOUR CASES"
          onClick={() => handleClick("/case-progress")}
          description="See how your cases are doing?"
          borderColor="var(--pink)"
          icon={CaseProgressIcon}
        />
      </div>
    </div>
  );
};

export default BrokerDashboard;

import React, { useState, useContext } from "react";
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";

import { stopEmulatingUserSaga } from "../../redux/actions/emulateActions/emulate";
import Menu from "../../components/menu/menu";
import { getUserSettings } from "../../redux/actions/actions";
import css from "./header.module.css";
import Button from "../../components/button/button";
import { AuthContext } from "../../utils/authProvider";
import NetworkLogoCheck from "./components/NetworkLogoCheck";

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [authenticated, setAuthenticated] = useState(false);
  const isEmulating = useSelector((store) => store.emulateUser?.startEmulating);
  const [isBroker, setIsBroker] = useState(false);
  const [isLawFirm, setIsLawFirm] = useState(false);
  const preferencesLoaded = useSelector(
    (store) => store.preferences?.value?.loaded
  );
  const { isAuthenticated, logout, getUser, getAccessToken } =
    React.useContext(AuthContext);

  React.useLayoutEffect(() => {
    async function init() {
      let authenticated = await isAuthenticated();
      if (authenticated) await getAccessToken();
      let user = await getUser();

      if (user != null && user.profile != null) {
        if (user.profile.roles.includes("BROKER_USER.PAGE")) setIsBroker(true);

        if (user.profile.roles.includes("LAW_FIRM_USER.PAGE"))
          setIsLawFirm(true);

        if (!preferencesLoaded) {
          handleGetSettings(user.profile);
        }
      }

      setAuthenticated(authenticated);
    }
    init();
  }, [preferencesLoaded]);

  const handleGetSettings = (userProfile) => {
    const isBroker = userProfile.roles.includes("BROKER_USER.PAGE");
    const canEmulateUser = userProfile.roles.includes("EMULATE_USER.PAGE");
    if (isBroker || (canEmulateUser && isEmulating))
      dispatch(getUserSettings(userProfile.sub));
  };

  const handleSignOut = () => {
    dispatch(stopEmulatingUserSaga());
    logout();
  };

  return (
    <header
      id="header"
      data-testid="header"
      className={classnames(css.flexcontainer)}
    >
      <Container fluid>
        <Row className={classnames(css.bottompad2x, css.flexitem)}>
          <Col md={1} xs={12} className={classnames(css.customGutter)}>
            {authenticated && (
              <Menu
                className={css.flexitem}
                isBroker={isBroker}
                isLawFirm={isLawFirm}
              />
            )}
            {!authenticated && <div className={css.flexitem}></div>}
          </Col>
          <Col md={6} xs={12}>
            <NetworkLogoCheck id="logo" data-testid="networklogo" />
          </Col>
          <Col md={4} xs={12}>
            <div className={classnames(css.flexitem, css.navigation)}>
              {authenticated && (
                <div>
                  <Button
                    id="dashboard"
                    datatestid="dashboard"
                    btnClass={classnames(css.headerlink)}
                    onClick={() => history.push("/dashboard")}
                    type="button"
                    text="DASHBOARD"
                  />
                  <span className={css.spacer}></span>
                  <Button
                    id="logout"
                    datatestid="logout"
                    btnClass={classnames(css.headerlink)}
                    onClick={handleSignOut}
                    type="button"
                    text="SIGN OUT"
                  />
                </div>
              )}
              {!authenticated && (
                <Button
                  id="visit-lms"
                  datatestid="visit-lms"
                  btnClass={classnames(css.headerlink)}
                  onClick={() => window.open("https://lms.com", "_blank")}
                  type="button"
                  text="VISIT LMS.COM"
                />
              )}
            </div>
          </Col>
          <Col md={1} xs={12}></Col>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
